// Fonts
$font-primary: 'BR Firma', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$font-icon: 'ysp-icons';

// Colours
$color-black: #000;
$color-white: #fff;
$color-blue: #3185FC;
$color-red: #E84855;

// Text Colours
$color-black-90: rgba($color-black, 0.9);
$color-black-80: rgba($color-black, 0.8);
$color-black-70: rgba($color-black, 0.7);
$color-black-60: rgba($color-black, 0.6);
$color-black-50: rgba($color-black, 0.5);
$color-black-40: rgba($color-black, 0.4);
$color-black-30: rgba($color-black, 0.3);
$color-black-20: rgba($color-black, 0.2);
$color-black-10: rgba($color-black, 0.1);

// Fonts
$font-size-default: 15px;

// Weights
$font-weight-regular: normal;
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Border
$border-radius-lg: 10px;
$border-radius-sm: 5px;
$border: 1px solid rgba($color-black, 0.1);
$box-shadow: 0 6px 14px 0 rgba($color-black, 0.1);

// Grid & responsiveness
$grid-gutter: 30px;
$padding: 30px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1140px;

$spacing-default: 15px;
$spacing-extra: 5px;
$spacing-small: calc(#{$spacing-default} / 2);
$spacing-medium: calc(#{$spacing-default} * 2);
$spacing-large: calc((#{$spacing-default} * 2) * 2);

$transition-transform: transform 0.2s ease-in-out;
$transition-all: all 0.2s ease-in-out;
