.tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &-link {
    align-self: flex-end;
    font-weight: $font-weight-medium;
    color: var(--theme-color, $color-black);
    padding: 10px 0;
    line-height: 20px;
    position: relative;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      color: var(--theme-color, $color-black);
      opacity: 1;
    }

    &.active {
      font-weight: $font-weight-semibold;
      color: var(--theme-color, $color-black);
      opacity: 1;

      &:before {
        content: '\e93d';
        position: absolute;
        top: calc(50% - 1px);
        right: calc(100%);
        transform: scaleX(-1) translate(0, -50%);
        font-family: $font-icon;
        font-size: 22px;
        margin-right: $spacing-default;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-shadow: -4px 0 12px 0 $color-black-10;
  z-index: 2;
  position: relative;
  width: 100%;

  &__header {
    display: flex;
    justify-content: center;
    padding: #{$spacing-default + $spacing-extra} 0;
    border-bottom: $border;

    @media (min-width: $breakpoint-tablet) {
      padding: $spacing-medium 0;
      border-bottom: 0;
    }

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      @media (min-width: $breakpoint-tablet) {
        width: auto;
        height: 50px;
      }

      img {
        object-fit: contain;
        width: 100%;
        max-height: 30px;
      }
    }
  }

  &__block {
    display: none;
    padding: 0 15px;

    @media (min-width: $breakpoint-tablet) {
      padding: 0 30px;
    }

    &.active {
      display: flex;
      flex-grow: 1;

      @media (min-width: $breakpoint-tablet) {
        flex-direction: column;
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;

    display: flex;
    flex-direction: column;
    padding: $spacing-default;

    background-color: $color-white;
    border-top: $border;

    @supports (backdrop-filter: blur(5px)) {
      backdrop-filter: blur(15px);
      background-color: rgba($color-white, 0.75);
    }

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      align-items: center;
      padding: 25px 30px;
      left: 320px;
    }

    @media (min-width: $breakpoint-desktop) {
      left: 380px;
    }

    &__step {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: $spacing-default;

      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 0;
      }

      &-eyebrow {
        font-weight: $font-weight-medium;
        font-size: $font-size-default - 2;
        line-height: 13px;
        margin-bottom: 7px;
        color: $color-black-40;
      }

      &-current {
        font-weight: $font-weight-semibold;
        line-height: 15px;
        font-size: $font-size-default;
        color: $color-black-80;
        margin: 0;
      }
    }

    &__controls {
      display: flex;

      & > * {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 25px;
        white-space: nowrap;
        margin-left: 0;

        @media (min-width: $breakpoint-tablet) {
          width: auto;
          padding: 14px 25px;

          & > *:first-child {
            margin-right: 10px;
          }
        }

        i {
          font-size: 20px;
        }

        span {
          display: none;

          @media (min-width: $breakpoint-tablet) {
            display: block;
          }
        }
      }

      button[data-step="increase"] {
        margin-left: 0;

        @media (min-width: $breakpoint-tablet) {
          margin-left: $spacing-default;
        }

        i {
          display: block;
          transform: scaleX(-1);
        }
      }

      .buttonInCart {
        margin-left: $spacing-default;
      }
    }
  }
}

.scrollable-content {
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-top: $spacing-default;
  padding-bottom: $spacing-default;

  @media (min-width: $breakpoint-tablet) {
    padding-bottom: $spacing-medium;
    padding-top: 0;
  }

  &--flex {
    display: flex;
  }

  .product-variant & {
    height: calc(100vh - 58px);
    padding-top: 0;

    @media (min-width: $breakpoint-tablet) {
      height: calc(100vh - 78px);
    }
  }
}
