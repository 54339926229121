.product {
  position: relative;
  cursor: pointer;

  &:nth-child(n + 4) {
    margin-top: $spacing-medium;
  }

  @media (max-width: $breakpoint-tablet) {
    &:nth-child(n + 2) {
      margin-top: $spacing-default;
    }
  }

  @media (min-width: 1400px) {
    &:nth-child(n + 4) {
      margin-top: 0;
    }

    &:nth-child(n + 5) {
      margin-top: $spacing-medium;
    }
  }

  @media (min-width: 1920px) {
    &:nth-child(n + 5) {
      margin-top: 0;
    }

    &:nth-child(n + 6) {
      margin-top: $spacing-medium;
    }
  }

  &--selected {
    border: 4px solid $color-white;
    border-radius: $border-radius-sm;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid $color-blue;
      border-radius: $border-radius-sm;
    }
  }

  &-check {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: background-color 0.1s ease-in, transform 0.1s ease-in;
    transform: scale(0.9);

    .product--selected & {
      background-color: $color-blue;
      top: 11px;
      right: 11px;
      transform: scale(1);
    }

    &:before {
      transform: scale(0.9);
      transition: transform 0.1s ease-in;

      .product--selected & {
        content: '\e916';
        font-family: $font-icon;
        font-size: 16px;
        line-height: 16px;
        color: $color-white;
        transform: scale(1);
      }
    }
  }

  &-name {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;

    background: $color-white;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05), 0 3px 5px 0 rgba(0,0,0,0.05);
    border-radius: 13px;
    padding: 6px 15px 8px;

    &__current {
      font-weight: $font-weight-semibold;
      font-size: $font-size-default - 2;
      color: $color-black-80;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 200px;
    }

    &__variants {
      display: block;
      font-weight: $font-weight-regular;
      color: $color-black-40;
      margin-top: 0;
      font-size: $font-size-default - 2;
      line-height: 13px;
    }

    .product--selected & {
      bottom: 16px;
    }
  }

  &-image {
    transform: scale(0.9);
    transition: transform 0.2s ease-in-out;
    width: 100%;

    .product--selected & {
      transform: scale(0.85);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.05);
    pointer-events: none;
  }
}

.product-variant {
  background: $color-white;
  box-shadow: -4px 0 12px 0 $color-black-10;
  border-radius: 30px 0 0 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 15;
  cursor: pointer;

  @media (min-width: $breakpoint-tablet) {
    width: 380px;
  }

  &__header {
    border-bottom: $border;
    padding: #{$spacing-default + $spacing-extra} 0;

    @media (min-width: $breakpoint-tablet) {
      padding: $spacing-medium 0;
    }

    &-title {
      font-weight: $font-weight-semibold;
      font-size: $font-size-default + 2;
      line-height: 17px;
      color: $color-black-80;
      text-align: center;
      margin: 0;
    }
  }

  &__type {
    border-bottom: $border;
    display: flex;
    align-items: flex-start;
    padding: $spacing-default;

    @media (min-width: $breakpoint-tablet) {
      padding: $spacing-medium;
    }
  }

  &__image {
    flex: 0 0 64px;
    position: relative;
    margin-right: $spacing-default;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-black, 0.05);
      pointer-events: none;
    }
  }

  &__content {
    &-title {
      font-size: $font-size-default;
      font-weight: $font-weight-medium;
      line-height: 19px;
      color: $color-black;
      margin: 0;
    }

    &-text {
      font-weight: $font-weight-medium;
      font-size: $font-size-default - 2;
      color: $color-black-60;
      line-height: 17px;
      margin: $spacing-small 0 $spacing-default;
    }
  }
}

.product-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  transition: background-color 0.2s ease-in;
  background-color: $color-white;

  @supports (backdrop-filter: blur(15px)) {
    backdrop-filter: blur(15px);
    background-color: rgba($color-white, 0.5);
  }
}

.product-information {
  max-width: 700px;
  margin: 0 auto;
  align-self: center;

  @media (min-width: $breakpoint-tablet) {
    padding-bottom: $spacing-medium;
  }

  &#product-order {
    display: none;

    @media (min-width: $breakpoint-tablet) {
      display: block;
    }
  }

  &--personalise {
    padding-bottom: 0;
    position: relative;
    align-self: auto;
  }

  &__header {
    display: flex;
    padding-bottom: $spacing-default;
    flex-direction: column;

    @media (min-width: $breakpoint-tablet) {
      padding-bottom: $spacing-medium;
      flex-direction: row;
    }

    .product-information--personalise & {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
    }
  }

  &__image {
    flex: 0 0 124px;
    position: relative;

    .product-information--personalise & {
      order: 2;
      max-width: 90%;

      @media (min-width: $breakpoint-tablet) {
        max-width: 450px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-black, 0.05);
      pointer-events: none;
    }

    &-src {
      transform: scale(0.9);
    }
  }

  &__summary {
    flex-grow: 1;
    align-self: flex-start;
    margin-top: $spacing-medium;

    @media (min-width: $breakpoint-tablet) {
      margin-left: $spacing-medium;
      align-self: center;
      margin-top: 0;
    }

    .product-information--personalise & {
      order: 1;
      margin-left: 0;
      text-align: center;
      margin-top: 0;
      width: 100%;
      margin-bottom: $spacing-default;

      @media (min-width: $breakpoint-tablet) {
        margin-bottom: $spacing-medium;
      }
    }

    &-eyebrow {
      font-weight: $font-weight-medium;
      color: $color-black-60;
      margin-bottom: $spacing-extra;
    }

    &-title {
      font-weight: $font-weight-semibold;
      font-size: $font-size-default + 2;
      line-height: 23px;
      color: $color-black;
      margin: 0;
    }
  }

  &__edit {
    align-self: center;

    button {
      white-space: nowrap;
    }
  }

  &__content {
    font-weight: $font-weight-medium;
    color: $color-black-60;
    line-height: 21px;

    b {
      color: $color-black-80;
    }
  }

  &__footer {
    text-align: center;
    margin-top: $spacing-default;

    @media (min-width: $breakpoint-tablet) {
      margin-top: $spacing-medium;
    }

    button {
      white-space: nowrap;
    }
  }
}
