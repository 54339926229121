.form {
  width: 100%;

  &.sectionBody {
    max-width: 700px;
    margin: auto;
  }

  &__row {
    display: flex;
    flex-grow: 1;

    &:nth-child(n + 2) {
      margin-top: ($spacing-default + $spacing-extra);
    }

    &-label {
      flex-grow: 1;

      &-text {
        font-family: $font-primary;
        font-weight: $font-weight-semibold;
        font-size: $font-size-default + 2;
        color: $color-black-80;
        display: block;
        margin-bottom: $spacing-default;
      }

      &-error {
        margin-top: ($spacing-default - $spacing-extra);
        display: none;

        &:not(:empty) {
          display: block;
        }

        p {
          font-size: ($font-size-default - 2);
          color: $color-red;
          font-family: $font-primary;
          font-weight: $font-weight-regular;
        }
      }
    }
  }

  &__fieldset {
    border: 0;
    margin: $spacing-medium 0 0;
    padding: 0;

    &:first-child {
      margin: 0;
    }
  }

  &__legend {
    font-weight: $font-weight-semibold;
    color: $color-black;
  }

  &__elem {
    position: relative;
    flex-grow: 1;

    &:last-of-type:not(:only-child) {
      margin-top: ($spacing-default + $spacing-extra);

      @media (min-width: $breakpoint-tablet) {
        margin-top: 0;
      }
    }
  }

  &-label {
    position: absolute;
    pointer-events: none;
    transition: all 0.1s ease-in;
    top: 20px;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 32px);
    left: 18px;
    line-height: 15px;
    color: $color-black-70;

    &--required {
      color: $color-red;
    }
  }

  &-input,
  &-textarea {
    width: 100%;
    height: 56px;
    border-radius: 10px;
    border: $border;
    padding: 18px 17px 0;
    appearance: none;
    font-family: $font-primary;
    outline: none;

    &:focus {
      border: 1px solid transparent;
      box-shadow: 0 0 0 2px $color-blue;
    }

    &.dirty ~ .form-label,
    &:focus ~ .form-label {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      top: 14px;
    }

    &.validated--error ~ .validateMsg:not(:empty) {
      font-size: 12px;
      color: $color-red;
      display: flex;
      margin-top: 10px;

      i {
        margin-right: 5px;
      }
    }
  }
  &-select-btn {
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  &-textarea {
    height: 125px;
    resize: none;
    padding-top: 28px;
  }
}