.grid-base,
%grid-base {
  display: flex;
  flex-wrap: wrap;
}

// One column
.grid-1,
%grid-1 {
  @extend %grid-base;

  & > * {
    width: 100%;
  }
}

// Two columns
.grid-2,
%grid-2 {
  @extend %grid-base;

  & > * {
    width: calc(50% - #{$grid-gutter} / 2);

    .form & {
      width: calc(50% - 20px / 2);

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
    }

    &:nth-child(2n + 1) {
      margin-right: $grid-gutter;

      .form & {
        margin-right: 20px;

        @media (max-width: $breakpoint-tablet) {
          margin-right: 0;
        }
      }
    }
  }
}

// Three columns
.grid-3,
%grid-3 {
  @extend %grid-base;

  & > * {
    width: calc(33.33% - 2 * #{$grid-gutter} / 3);

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    @media (min-width: 1400px) {
      width: calc(25% - 3 * #{$grid-gutter} / 4);
    }

    @media (min-width: 1920px) {
      width: calc(20% - 4 * #{$grid-gutter} / 5);
    }

    &:nth-child(3n + 2) {
      margin-left: $grid-gutter;
      margin-right: $grid-gutter;
    }

    @media (max-width: $breakpoint-tablet) {
      &:nth-child(3n + 2) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media (min-width: 1400px) {
      &:nth-child(3n + 2) {
        margin-left: 0;
        margin-right: 0;
      }

      &:nth-child(4n + 1),
      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        margin-right: $grid-gutter;
      }
    }

    @media (min-width: 1920px) {
      &:nth-child(4n + 1),
      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        margin-right: 0;
      }

      &:nth-child(5n + 2),
      &:nth-child(5n + 4) {
        margin-left: $grid-gutter;
        margin-right: $grid-gutter;
      }
    }
  }
}

// Four columns
.grid-4,
%grid-4 {
  @extend %grid-base;

  & > * {
    width: calc(25% - 3 * #{$grid-gutter} / 4);

    &:nth-child(4n + 1),
    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      margin-right: $grid-gutter;
    }
  }
}

// Five columns
.grid-5,
%grid-5 {
  @extend %grid-base;

  & > * {
    width: calc(20% - 4 * #{$grid-gutter} / 5);

    &:nth-child(5n + 2),
    &:nth-child(5n + 4) {
      margin-left: $grid-gutter;
      margin-right: $grid-gutter;
    }
  }
}

// Six columns
.grid-6,
%grid-6 {
  @extend %grid-base;

  & > * {
    width: calc(16.66% - 5 * #{$grid-gutter} / 6);

    .no-flex & {
      float: left;
    }

    &:nth-child(6n + 1),
    &:nth-child(6n + 2),
    &:nth-child(6n + 3),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      margin-right: $grid-gutter;
    }
  }
}