// BR Firma
@font-face {
  font-family: 'BR Firma';
  src: url('/fonts/brfirma/BRFirma-Regular.eot');
  src: local('BR Firma'), local('BRFirma-Regular'),
  url('/fonts/brfirma/BRFirma-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/brfirma/BRFirma-Regular.woff2') format('woff2'),
  url('/fonts/brfirma/BRFirma-Regular.woff') format('woff'),
  url('/fonts/brfirma/BRFirma-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: url('/fonts/brfirma/BRFirma-SemiBold.eot');
  src: local('BR Firma Semi Bold'), local('BRFirma-SemiBold'),
  url('/fonts/brfirma/BRFirma-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/brfirma/BRFirma-SemiBold.woff2') format('woff2'),
  url('/fonts/brfirma/BRFirma-SemiBold.woff') format('woff'),
  url('/fonts/brfirma/BRFirma-SemiBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: url('/fonts/brfirma/BRFirma-Medium.eot');
  src: local('BR Firma Medium'), local('BRFirma-Medium'),
  url('/fonts/brfirma/BRFirma-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/brfirma/BRFirma-Medium.woff2') format('woff2'),
  url('/fonts/brfirma/BRFirma-Medium.woff') format('woff'),
  url('/fonts/brfirma/BRFirma-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ysp-icons';
  src:  url('/fonts/yspicons/ysp-icons.eot?ts864a');
  src:  url('/fonts/yspicons/ysp-icons.eot?ts864a#iefix') format('embedded-opentype'),
  url('/fonts/yspicons/ysp-icons.woff2?ts864a') format('woff2'),
  url('/fonts/yspicons/ysp-icons.ttf?ts864a') format('truetype'),
  url('/fonts/yspicons/ysp-icons.woff?ts864a') format('woff'),
  url('/fonts/yspicons/ysp-icons.svg?ts864a#ysp-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: $font-icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google-plus:before {
  content: "\e93e";
}
.icon-sort-down:before {
  content: "\e946";
}
.icon-caret-right:before {
  content: "\e92e";
}
.icon-caret-left:before {
  content: "\e92f";
}
.icon-caret-up:before {
  content: "\e930";
}
.icon-caret-down:before {
  content: "\e92c";
}
.icon-edit:before {
  content: "\e927";
}
.icon-pencil:before {
  content: "\e928";
}
.icon-ed-effect:before {
  content: "\e939";
}
.icon-plus:before {
  content: "\e92d";
}
.icon-minus:before {
  content: "\e929";
}
.icon-exclamation-sign:before {
  content: "\e935";
}
.icon-ed-warning:before {
  content: "\e935";
}
.icon-warning-sign:before {
  content: "\e935";
}
.icon-zoom-minus:before {
  content: "\e93a";
}
.icon-zoom-plus:before {
  content: "\e93b";
}
.icon-search:before {
  content: "\e938";
}
.icon-save:before {
  content: "\e942";
}
.icon-download:before {
  content: "\e92a";
}
.icon-upload:before {
  content: "\e92b";
}
.icon-info-sign:before {
  content: "\e947";
}
.icon-question-circle:before {
  content: "\e948";
}
.icon-gear:before {
  content: "\e949";
}
.icon-refresh:before {
  content: "\e94a";
}
.icon-ed-colorpicker:before {
  content: "\e941";
}
.icon-logo:before {
  content: "\e940";
}
.icon-calendar:before {
  content: "\e93f";
}
.icon-speed:before {
  content: "\e93c";
}
.icon-arrow-left:before {
  content: "\e93d";
}
.icon-discount-label:before {
  content: "\e920";
}
.icon-sticker:before {
  content: "\e91f";
}
.icon-sort:before {
  content: "\e933";
}
.icon-filter:before {
  content: "\e934";
}
.icon-sort-down-arrow:before {
  content: "\e936";
}
.icon-sort-up-arrow:before {
  content: "\e937";
}
.icon-download-invoice:before {
  content: "\e932";
}
.icon-remove:before {
  content: "\e926";
}
.icon-ed-close:before {
  content: "\e926";
}
.icon-arrow-left-up:before {
  content: "\e922";
}
.icon-arrow-right-up:before {
  content: "\e923";
}
.icon-arrow-left-down:before {
  content: "\e924";
}
.icon-arrow-right-down:before {
  content: "\e925";
}
.icon-circle:before {
  content: "\e91d";
}
.icon-paintstroke:before {
  content: "\e91c";
}
.icon-star:before {
  content: "\e91b";
}
.icon-truck:before {
  content: "\e904";
}
.icon-giftbox:before {
  content: "\e903";
}
.icon-heart:before {
  content: "\e91a";
}
.icon-approved:before {
  content: "\e91e";
}
.icon-giftcard:before {
  content: "\e921";
}
.icon-envelope-open:before {
  content: "\e917";
}
.icon-checkmark:before {
  content: "\e916";
}
.icon-ok:before {
  content: "\e916";
}
.icon-thuiswinkel:before {
  content: "\e913";
}
.icon-menu:before {
  content: "\e914";
}
.icon-hamburger:before {
  content: "\e915";
}
.icon-chevron-up:before {
  content: "\e94f";
}
.icon-chevron-down:before {
  content: "\e950";
}
.icon-chevron-left:before {
  content: "\e911";
}
.icon-chevron-right:before {
  content: "\e912";
}
.icon-trashcan:before {
  content: "\e910";
}
.icon-trash:before {
  content: "\e910";
}
.icon-envelope-circle:before {
  content: "\e90a";
}
.icon-whatsapp-circle:before {
  content: "\e919";
}
.icon-phone-circle:before {
  content: "\e918";
}
.icon-facebook-circle:before {
  content: "\e90b";
}
.icon-linkedin-circle:before {
  content: "\e931";
}
.icon-google-circle:before {
  content: "\e90c";
}
.icon-instagram-circle:before {
  content: "\e90d";
}
.icon-pinterest-circle:before {
  content: "\e90e";
}
.icon-twitter-circle:before {
  content: "\e90f";
}
.icon-phone-open:before {
  content: "\e909";
}
.icon-contact:before {
  content: "\e908";
}
.icon-faq:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e906";
}
.icon-business:before {
  content: "\e905";
}
.icon-home:before {
  content: "\e902";
}
.icon-cart-empty:before {
  content: "\e900";
}
.icon-ed-cart:before {
  content: "\e900";
}
.icon-cart-full:before {
  content: "\e901";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-phone:before {
  content: "\f095";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-mobile-phone:before {
  content: "\f10b";
}
.icon-spinner:before {
  content: "\f110";
}