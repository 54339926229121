%btn {
  transition: $transition-all;
  font-family: $font-primary;
  display: inline-block;
  border: none;

  font-weight: normal;
  cursor: pointer;
  outline: none;
}

.btn {
  @extend %btn;
  padding: 14px 25px;
  border-radius: 43px;

  &.hidden {
    display: none !important;
  }

  & > * {
    pointer-events: none;
  }

  &--primary {
    color: $color-white;
    background-color: $color-black; // fallback ie11
    background-color: var(--theme-color);
    font-weight: $font-weight-semibold;
    font-size: $font-size-default;

    &[disabled] {
      opacity: 0.2;
    }
  }

  &--secondary {
    background-color: $color-white;
    box-shadow: 0 0 0 1px $color-black-10;
    font-weight: $font-weight-medium;
    font-size: $font-size-default;
  }

  &--small {
    padding: 10px 15px;
    border-radius: 23px;
  }

  &.hidden {
    display: none;
  }
}
