.hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: $breakpoint-tablet) {
    position: static;
    flex-grow: 1;
  }
}