.sidebar {
  padding: $spacing-medium #{$spacing-default + $spacing-extra};
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba($color-white, 0.8);

  @supports (backdrop-filter: blur(15px)) {
    backdrop-filter: blur(15px);
  }

  &--image {
    position: absolute;
    transform: translate(-100%, 0);
  }

  @media (min-width: $breakpoint-tablet) {
    padding: $spacing-large $spacing-medium $spacing-medium;
    max-width: 320px;

    box-shadow: 4px 0 12px 0 $color-black-10;
    background-color: $color-white;
    flex-shrink: 0;

    &--image {
      position: relative;
      transform: translate(0);
      box-shadow: none;
      background: $color-white;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba($color-white, 0.80);

        @supports (backdrop-filter: blur(15px)) {
          backdrop-filter: blur(15px);
          background-color: rgba($color-white, 0.15);
        }
      }
    }
  }

  @media (min-width: $breakpoint-desktop) {
    max-width: 380px;
  }

  &__header {
    display: flex;
    justify-content: center;

    &-img {
      width: 60%;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;

    &-title {
      font-weight: $font-weight-semibold;
      font-size: $font-size-default + 2;
      color: $color-black;
      line-height: 17px;
      margin-bottom: $spacing-default;
    }

    &-text {
      font-weight: $font-weight-regular;
      color: $color-black-80;
      line-height: 21px;
      display: none;
      margin-bottom: $spacing-default + 5;

      &:not(:empty) {
        display: block;
      }
    }

    &__form {
      align-self: center;

      input[type="text"] {
        background: $color-white;
        box-shadow: 0 0 0 1px rgba(0,0,0,0.07), 0 1px 2px 0 rgba(0,0,0,0.05), 0 3px 5px 0 rgba(0,0,0,0.05);
        border-radius: 24px;
        width: 100%;
        padding: 16px 25px;
        border: none;
        font-weight: $font-weight-regular;
        font-family: $font-primary;
        outline: none;
        appearance: none;

        &::-webkit-input-placeholder {
          color: $color-black-40;
        }
      }

      input[type="submit"] {
        width: 100%;
        margin-top: -5px;

        @media (min-width: $breakpoint-tablet) {
          width: auto;
          margin-left: auto;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    &-text {
      font-weight: $font-weight-medium;
      font-size: $font-size-default - 4;
      color: $color-black-40;

      display: flex;

      span {
        display: inline-block;
      }

      a {
        display: inline-block;
      }

      img {
        height: 18px;
        margin-left: $spacing-extra;
      }
    }
  }
}