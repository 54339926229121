* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: $font-primary;
  font-weight: $font-weight-regular;
  font-size: $font-size-default;
  line-height: 15px;
  letter-spacing: -0.2px;
  color: $color-black-80;
  height: 100vh; // fallback for IE11 and if height isn't set
  height: var(--height);

  overflow-y: hidden;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  transition: color 0.2s ease;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

p {
  line-height: 21px;
  font-size: $font-size-default;
  margin: 0;
}